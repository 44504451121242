/* app/ui/feature-carousel/feature-carousel */

const selectors = {
	PrevBtn: '.js-feature-carousel__btn-prev',
	NextBtn: '.js-feature-carousel__btn-next',
	contents: '.js-feature-carousel-content',
	carousel: '.js-feature-carousel',
	slideIndex: '.js-feature-carousel__index',
};

var $featureCarouselContainer;
var $featureCarouselPrevBtn;
var $featureCarouselNextBtn;
var $featureCarouselContent;
var $featureCarouselIndex;
var activeSlide = 1;
var preSlide;
var nextSlide;
var totalSlides;

const module = {
	init: function () {
		module._initElement();
		module._initEvent();
	},

	_initElement: function () {
		$featureCarouselContainer = document.querySelector(selectors.carousel);
		$featureCarouselPrevBtn = $featureCarouselContainer.querySelector(selectors.PrevBtn);
		$featureCarouselNextBtn = $featureCarouselContainer.querySelector(selectors.NextBtn);
		$featureCarouselContent = $featureCarouselContainer.querySelectorAll(selectors.contents);
		$featureCarouselIndex = $featureCarouselContainer.querySelector(selectors.slideIndex);

		totalSlides = $featureCarouselContainer.getAttribute("data-total");
	},

	_initEvent: function () {
		//console.log($featureCarouselPrevBtn)
		if ($featureCarouselPrevBtn) {
			$featureCarouselPrevBtn.addEventListener("click", module._PrevBtnClickHandler);
		}

		if ($featureCarouselNextBtn) {
			$featureCarouselNextBtn.addEventListener("click", module._NextBtnClickHandler);
		}
    },

	_PrevBtnClickHandler: function () {
		//console.log("pre click");
		if (activeSlide == 1) {
			activeSlide = totalSlides;
		} else {
			activeSlide--;
		}
		//console.log(activeSlide);
		module._slideTo(activeSlide);
	},

	_NextBtnClickHandler: function () {
		//console.log("next click");
		if (activeSlide == totalSlides) {
			activeSlide = 1;
		} else {
			activeSlide++;
		}

		module._slideTo(activeSlide);
		//console.log(activeSlide);;
	},

	_slideTo: function (i) {
		$featureCarouselContent.forEach($slide => {
			const slideIndex = $slide.getAttribute("data-index");

			$slide.setAttribute("aria-current", false);
			if (slideIndex == i) {
				$slide.setAttribute("aria-current", true);
            }
			
		});

		$featureCarouselIndex.setAttribute("data-active-index", i);
		$featureCarouselIndex.innerHTML = i;
		$featureCarouselContainer.setAttribute("data-current-slide", i);
    }
}

const FeatureCarousel = {
	init: module.init,
};

export { FeatureCarousel };