import { publish } from 'Util/pubsub';

const selectors = {
	container: '.js-popup-modal',
	formContainer: '.js-popup-modal__form-container',
	successMessage: '.js-popup-modal__success-message',
	errorMessage: '.js-popup-modal__error-message',
	retryTrigger: '.js-popup-modal__retry-button',
	form: '.js-popup-modal__form',
};

const displayDelaySeconds = 4;

const module = {
	init: function () {

		document.addEventListener("DOMContentLoaded", function (event) {

			if (document.querySelector(".js-popup-modal")) {

				window.setTimeout(module.form._show, displayDelaySeconds * 1000);
				module._initEvents();

			}

		});

	},

	_initEvents: function () {
		document.querySelector(selectors.formContainer).addEventListener('submit', module.form._submit);
		document.querySelector(selectors.retryTrigger).addEventListener('click', module._resetDisplay);
	},

	cookie: {
		_getValue: function (name) {
			const regex = new RegExp(`(^| )${name}=([^;]+)`);
			let match = document.cookie.match(regex);
			if (match) {
				return match[2];
			}
			return null;
		},

		_setValue: function (name, value) {
			const d = new Date();
			d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
			document.cookie = `${name}=${value};expires=${d.toUTCString()};path=/`;
		}
	},

	form: {

		_show: function () {
			let $popup = document.querySelector(selectors.container);
			let id = $popup.id;

			if (module.cookie._getValue(id) === 'true') {
				return;
			}

			if ($popup) {
				publish('/modal/open', id);
				module.cookie._setValue(id, true);
			}
		},

		_submit: function (event) {
			event.preventDefault();

			let $form = document.querySelector(selectors.form);
			let action = $form.action;
			let data = new FormData($form);
			let options = {
				method: 'POST',
				body: data
			};

			let $button = $form.querySelector('input[type=submit]');
			$button.disabled = true;

			fetch(action, options)
				.then(module.form._callback)
				.catch(module.form._error)
				;
		},

		_callback: function (response) {
			//console.log(response);
			if (response.status >= 200 && response.status < 300) {
				document.querySelector(selectors.formContainer).classList.add('u-hidden');
				document.querySelector(selectors.successMessage).classList.remove('u-hidden');
			} else {
				document.querySelector(selectors.formContainer).classList.add('u-hidden');
				document.querySelector(selectors.errorMessage).classList.remove('u-hidden');
			}
		},

		_error: function (response) {
			console.error(response);
		}
	},

	_resetDisplay: function (event) {
		event.preventDefault();

		let $form = document.querySelector(selectors.formContainer);
		let $button = $form.querySelector('input[type=submit]');

		$form.classList.remove('u-hidden');
		document.querySelector(selectors.successMessage).classList.add('u-hidden');
		document.querySelector(selectors.errorMessage).classList.add('u-hidden');

		$button.disabled = false;
	},
};

const PopupModal = {
	init: module.init
};

export { PopupModal };