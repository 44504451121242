import $ from 'jquery';

import { NavLoad } from 'App/nav/load';
import { TrackLoad } from 'App/track/load';
import { ExpandCollapse } from 'App/expand-collapse/expand-collapse';
import { VideoPlayer } from 'App/video/video.player';
import { Social as SocialShare } from 'App/social/share';
import { AjaxReplace } from 'App/ajaxreplace/ajaxreplace';
import { Cookie } from 'App/cookie/cookie';
import { selectLink } from 'App/select-link/select-link';
import { resources } from 'App/resources/resources';
import { Validate as formValidate } from 'App/form/validate';
import { modal } from 'App/modal/modal';
import { videoGallery } from 'App/video/gallery';
import { scrollAppear } from 'App/scroll-appear/scroll-appear';
import { tabs } from 'App/tabs/tabs';
import { FeatureCarousel } from 'App/feature-carousel/feature-carousel';
import { PopupModal } from 'App/popup/popup-modal';
import { publish } from 'Util/pubsub';
import { throttle } from 'Util/throttle';

import 'magnificPopup';

var $window = $(window);

TrackLoad.init();
NavLoad.init();
AjaxReplace.init();
SocialShare.init();
Cookie.init();
selectLink.init();
videoGallery.init();
scrollAppear.init();

// Publish Resize event
var publishResizeEvent = function () {
	publish('/window/resize');
};

var publishScrollEvent = function () {
	publish('/window/scroll');
};

// ExpandCollapse
ExpandCollapse.init();

// Resources
resources.init();

// Form Validation
formValidate.init();

// Video Macros
VideoPlayer.init();

// Umbraco Forms
// init function will load required dependencies if an umbraco form is present
var $umbracoForms = $('.js-umbraco-form');
if ($umbracoForms.length) {
	import(/* webpackChunkName: "UmbracoForms" */ 'App/form/form.umbraco').then(({ UmbracoForms }) => {
		UmbracoForms.init();
	});
}

// Homepage hero
const $heroRotator = document.querySelector('.js-hero-rotator');
if ($heroRotator) {
	import(/* webpackChunkName: "HeroRotator" */ 'App/hero/rotator').then(({ heroRotator }) => {
		heroRotator.init();
	});
}

// Maps
var $maps = $('.js-map');
if ($maps.length) {
	import(/* webpackChunkName: "MapLoad" */ 'App/map/map.load').then(({ MapLoad }) => {
		MapLoad.init($maps);
	});
}

// Rotators
var $rotators = $('.js-rotator');
if ($rotators.length) {
	import(/* webpackChunkName: "Rotator" */ 'App/rotator/rotator').then(({ Rotator }) => {
		Rotator.init($rotators);
	});
}

// Modal
modal.init({
	onShow: function ($modal) {
		var $rotators = $modal.find('.js-rotator');
		var thisFlick;
		$rotators.each(function (i, el) {
			thisFlick = $(el).data('flickity-instance');

			if (thisFlick) {
				thisFlick.resize();
			}
		});
	}
});
	
PopupModal.init();

// Filter
if (document.querySelector('.js-filter__modal-trigger')) {
	import(/* webpackChunkName: "Filter" */ 'App/filter/filter').then(({ filter }) => {
		filter.init();
	});
}

// Back to top
if ($('.js-back-to-top').length) {
	import(/* webpackChunkName: "BackToTop" */ 'App/back-to-top/back-to-top').then(({ BackToTop }) => {
		BackToTop.init();
	});
}

if ($('.js-anchor-trigger__btn').length) {
	import(/* webpackChunkName: "AnchorTrigger" */ 'App/anchor-trigger/anchor-trigger').then(({ AnchorTrigger }) => {
		AnchorTrigger.init();
	});
}

// Tabs

const tabContent = document.querySelectorAll('.js-tab-link');
if (tabContent.length) {
	import(/* webpackChunkName: "Filter" */ 'App/tabs/tabs').then(({ tabs }) => {
		tabs.init();
	});
}

//FeatureCarousel
const FeatureCarouselControl = document.querySelectorAll('.js-feature-carousel');
if (FeatureCarouselControl.length) {
	import(/* webpackChunkName: "Filter" */ 'App/feature-carousel/feature-carousel').then(({ FeatureCarousel }) => {
		FeatureCarousel.init();
	});
}


$window.on('resize', throttle(publishResizeEvent, 250));
$window.on('scroll', publishScrollEvent);

var $datepickers = $('.js-datepicker');
if ($datepickers.length) {
	$datepickers.each(function () {
		var $datepicker = $(this);

		if (window.matchMedia('(pointer: coarse)').matches) {
			$datepicker.attr('readonly', 'readonly');
		}

		import(/* webpackChunkName: "Pikaday" */ 'pikaday').then(({ default: Pikaday }) => {
			new Pikaday({ field: this, yearRange: 10 });
		});
	});
}

// Single and Multi Image Lightbox
$('.js-lightbox-single').magnificPopup({
	delegate: '.js-lightbox-image',
	type: 'image'
});

$('.js-lightbox-gallery').each(function() {
	$(this).magnificPopup({
		delegate: '.js-lightbox-image',
		type: 'image',
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
		}
	});
});

$('.js-video-group__item').magnificPopup({

	type: 'iframe'

});

/////////////////
// Page/Detail //
/////////////////
import { VideoLoad } from 'App/video/load';
import { Calendar } from 'App/event/calendar';

// Video Macros
VideoLoad.init();

// Check DOM for elements requiring JS
var $tables = $('.c-rte').find('table');

// Table Scroll
if ($tables.length) {
	import(/* webpackChunkName: "TableScroll" */ 'tablescroll').then(() => {
		$tables.tableScroll();
	});
}

// Event calendar add
Calendar.init();

///////////////
// Page/Form //
///////////////
import { FormDelegate } from 'App/form/delegate';

FormDelegate.init();
