
/* app/ui/track/preload */

import $ from 'jquery';
import { Util } from 'App/track/analytics/util';

var preloadEvents = window._trackPreloadEvents;

var Track = {
	init: function () {
		var preloadEvents = window._trackPreloadEvents;

		if ( typeof preloadEvents != 'undefined' && preloadEvents.length ) {
			for ( var i = 0; i < preloadEvents.length; i++ ) {
				var data = preloadEvents[i];

				Util.publishEvent( data );
			}
		}
	}
};

export { Track };
