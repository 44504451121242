import $ from 'jquery';
import { listen, getQueryStringObject, buildQueryString } from 'Util/core';
import { activate } from 'Util/activate';

const selectors = {
	link: '.js-tab-link',
	content: '.js-tab-content'
};

const dataSelectors = {
	id: 'tab-id'
};

const historySupport = window.history && window.history.pushState;

const module = {
	init: function () {
		module._initEvents();

		module._detectCurrentTab();
	},

	_initEvents: function () {
		//$(document)
			//.on('click keydown', selectors.link, activate(module._selectTabEvent));
		listen(selectors.link, 'click', activate(module._selectTabEvent));
		window.addEventListener('popstate', module._detectCurrentTab);
	},

	_selectTabEvent: function (e) {
		e.preventDefault();

		var $link = $(e.target).closest(selectors.link);
		var id = $link.data(dataSelectors.id);

		module._selectTab(id);
	},

	_selectTab: function (id) {
		var querystring;

		module._showTab(id);

		if (historySupport) {
			querystring = getQueryStringObject();
			querystring.tab = id;
			querystring = buildQueryString(querystring);

			history.pushState({}, document.title, querystring);
		}
	},

	_showTab: function (id) {
		var $links = $(selectors.link);
		var $selectedLink = $links.filter('[data-' + dataSelectors.id + '="' + id + '"]');

		var $tabs = $(selectors.content);
		var $selectedTab = $tabs.filter('[data-' + dataSelectors.id + '="' + id + '"]');

		$links.removeAttr('aria-current');
		$selectedLink.attr('aria-current', true);

		$tabs.removeAttr('aria-current');
		$selectedTab.attr('aria-current', true);

		$(window).trigger('scroll'); // Force lazyloading
	},

	_showDefaultTab: function () {
		var $links = $(selectors.link);
		var id = $links.first().data(dataSelectors.id);

		module._showTab(id);
	},

	_detectCurrentTab: function () {
		var query = getQueryStringObject();

		var id = query.tab;

		if (id) {
			module._showTab(id);
		} else {
			module._showDefaultTab();
		}
	}
}

const tabs = {
	init: module.init,
};

export { tabs };