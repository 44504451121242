/* app/ui/track/util */

import $ from 'jquery';
import { Universal as Analytics } from 'App/track/analytics/universal';

var selectors = {};

var Util = {
	publishEvent: function ( data ) {
		Analytics.sendEvent( data );
	},

	publishPage: function ( data ) {
		Analytics.sendPageView( data );
	}
};

export { Util };
